import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SearchIcon from "@mui/icons-material/Search";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  TableRow,
} from "@mui/material";
import "./SFGstore.css";
import { getSFGStoreData } from "./services";
import { images } from "../../config/images";

const SFGstore = () => {

  const navigate = useNavigate();
  const [debouncedSearchText, setDebouncedSearchText] = useState({});
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [SFGstoreData, setSFGStoreData] = useState([]);

  const data = [
    { class: "00", size: 3, length: 380, type: "SC", quantity: 825 },
    { class: "1", size: 9, length: 420, type: "RE", quantity: 760 },
    { class: "2", size: 10, length: 380, type: "SC", quantity: 825 },
    { class: "3", size: 11, length: 420, type: "RE", quantity: 760 },
  ];

  const initiallValue = {
    class_id__class_name: "",
    size__size_name: "",
    length__length: "",
    scre_type: "",
    quantity: "",
  };

  const [searchText, setSearchText] = useState(initiallValue);


  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchText(searchText); // Update the debounced searchText
    }, 500); // 500ms debounce delay

    return () => {
      clearTimeout(handler); // Cleanup the timeout
    };
  }, [searchText]); // Effect runs when searchText changes

  const handleSearchData = async (e) => {
    // Destructure the name and value of the input field that triggered the change
    const { name, value } = e.target;

    // Update the searchText state object with the new value for the specified input field
    setSearchText({ ...searchText, [name]: value });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const fetchData = async () => {
    const searchParams = {
      search: debouncedSearchText,
      page: page,
      rowsPerPage: rowsPerPage,
    };
    try {
      const response = await getSFGStoreData(searchParams);
      if (response.data.success == true) {
        setSFGStoreData(response.data.payload);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (Object.keys(debouncedSearchText).length > 0) {
      fetchData();
    }
  }, [page, rowsPerPage, debouncedSearchText]);

  return (
    <div className="dashboard-wrapper page-wraper">
      <div className="page-header">
        <Link to="/dashboard" className="page-back-btn">
          <ArrowBackIcon />
          <span>SFG Store</span>
        </Link>
        <div className="d-flex gap-2">
          <div>
          <img src={images.printIcon} alt="" style={{cursor:"pointer",width:"55px"}} onClick={()=> navigate("/sfg-printer")}/>
          </div>
      
          <button
          className="page-header-btn-params"
          style={{ fontSize: "17px" }}
          onClick={() => navigate("/sfg-history")}
        >
          SFG History
        </button>
        </div>
       
      </div>
      <div className="pt-3 table-responsive">
        <TableContainer component={Paper}>
          <Table className="sfg-score-table">
            <TableHead>
              <TableRow style={{ backgroundColor: "#1c447c" }}>
                <TableCell style={{ color: "#fff" }}>Class</TableCell>
                <TableCell style={{ color: "#fff" }}>Size</TableCell>
                <TableCell style={{ color: "#fff" }}>Length</TableCell>
                <TableCell style={{ color: "#fff" }}>Type</TableCell>
                <TableCell style={{ color: "#fff" }}>Quantity</TableCell>
              </TableRow>
              <TableRow>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Class"
                    name="class_id__class_name"
                    value={searchText.class_id__class_name}
                    onChange={handleSearchData}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Size"
                    name="size__size_name"
                    value={searchText.size__size_name}
                    onChange={handleSearchData}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Length"
                    name="length__length"
                    value={searchText.length__length}
                    onChange={handleSearchData}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Type"
                    name="scre_type"
                    value={searchText.scre_type}
                    onChange={handleSearchData}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Quantity"
                    name="quantity"
                    value={searchText.quantity}
                    onChange={handleSearchData}
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {SFGstoreData?.results?.map((row, index) => (
                <TableRow
                  key={index}
                  style={index % 2 ? { backgroundColor: "#f9f9f9" } : null}
                >
                  <TableCell>{row.class_name}</TableCell>
                  <TableCell>{row.size_name}</TableCell>
                  <TableCell>{row.length_name}</TableCell>
                  <TableCell>{row.scre_type}</TableCell>
                  <TableCell>{row.quantity}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <TablePagination
        className="table-pagination"
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={SFGstoreData ? SFGstoreData.count : null}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default SFGstore;
