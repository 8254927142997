import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Grid, Paper, Checkbox, Button } from '@mui/material';
import "./ProductionLine.css";

const ProductionLineStripping = () => {
    const [selectedBatches, setSelectedBatches] = useState([]);
    const navigate = useNavigate();
    // Sample Data
    const batchNumbers = Array.from({ length: 20 }, (_, i) => `I${String(i + 1).padStart(4, '0')}`);
    const uids = Array.from({ length: 20 }, (_, i) => `I${String(i + 1).padStart(6, '0')}`);

    const handleBatchSelect = (batch) => {
        setSelectedBatches((prevSelected) =>
            prevSelected.includes(batch)
                ? prevSelected.filter((item) => item !== batch) // Unselect if already selected
                : [...prevSelected, batch] // Select if not selected
        );
    };

    const handleSubmit = () => {
        navigate("/production-line");
    }

    return (
        <div className="min-h-screen bg-[#f5f3ff] p-8 dashboard-wrapper page-wraper">
            <div className="page-header">
                <Link to="/dashboard" className="page-back-btn">
                    <ArrowBackIcon />
                    <span>Production Line</span>
                </Link>
            </div>

            {/* Grid Layout */}
            <div className="production-line-stripping">
                <Grid container spacing={2}>

                    {/* Batch Numbers Column */}
                    <Grid item xs={3}>
                        <div className="batch-column">
                            <h3>Batch No</h3>
                            <div className="batch-item">
                                {batchNumbers.map((batch, index) => (
                                    <div key={index} className='batch-items'>
                                        <Checkbox
                                            checked={selectedBatches.includes(batch)}
                                            onChange={() => handleBatchSelect(batch)}
                                        />
                                        <span>{batch}</span>
                                    </div>
                                ))}
                            </div>

                        </div>
                    </Grid>

                    {/* UID Column */}
                    <Grid item xs={9}>
                        <div className="uid-column">
                            <h3>UID</h3>
                            <div className="uid-item">
                                <div className="uid-items">
                                    {uids.map((uid, index) => (
                                        <div key={index} className="uid-items-each">
                                            {uid}
                                        </div>
                                    ))}
                                </div>
                                <div className="assign-btn-container">
                                    <button className='assign-button' onClick={() => handleSubmit()}>Assign</button>
                                </div>
                            </div>

                        </div>
                    </Grid>

                </Grid>
            </div>

            {/* Assign Button */}

        </div>
    );
};

export default ProductionLineStripping;
