import { request } from "../../api/api";

export const getRobotHistory = (params) => {
  return request.post(`getRobotHistory/`, params);
};

export const updateRobotParametersStatus = (params) => {
  return request.post(`updateRobotParametersStatus/`, params);
};

export const abortBatch = (params) => {
  return request.post(`abortBatch/`, params);
};

export const filterUid = (params) => {
  console.log("filter_search   &page=${params.page+1} ", params)
  return request.post(`filter_search/?search=${params.uid_type}&page=${params.page + 1}`);
};

export const exportExcel = (params) => {
  return request.post(`exportDippingParameter/`, params);
}

export const checkbatchstatus = (params) => {
  return request.post(`checkbatchstatus/`, params)
}

export const getDippingParameterBatch = (searchParams) => {
  let queryParams = [
    searchParams.search.Date ? `search=${searchParams.search.Date}` : "",
    searchParams.search.B_No ? `batch_type=${searchParams.search.B_No}` : "",
    searchParams.search.Lot_No ? `lot_number=${searchParams.search.Lot_No}` : "",
    searchParams.search.Scre ? `scre=${searchParams.search.Scre}` : "",
    searchParams.search.Class
      ? `class_id__class_name=${searchParams.search.Class}`
      : "",
    searchParams.search.Size ? `size__size_name=${searchParams.search.Size}` : "",
    searchParams.search.LR ? `r_or_l=${searchParams.search.LR}` : "",
    searchParams.start_datetime ? `start_datetime=${searchParams.start_datetime}` : "",
    searchParams.end_datetime ? `end_datetime=${searchParams.end_datetime}` : "",
  ]
    .filter((param) => param !== "")
    .join("&");

  return request.get(
    `getDippingParameterBatch/?${queryParams}&${
      searchParams.rowsPerPage ? `page_size=${searchParams.rowsPerPage}` : ""
    }&page=${searchParams.page + 1}`
  );
};
