export const images = {
  logo: require("../assets/images/logo.png"),
  advanceAnalytics: require("../assets/images/advance-analytics.png"),
  arrowIcon: require("../assets/images/arrow icon.png"),
  closeIcon: require("../assets/images/close.png"),
  uploadIcon: require("../assets/images/upload_icon.png"),
  completed: require("../assets/images/completed.png"),
  dippingParameter: require("../assets/images/dipping-parameters.png"),
  dispatched: require("../assets/images/dispatched.png"),
  downloadbtn: require("../assets/images/upload_logo_production.png"),
  downloadPdf: require("../assets/images/downloadpdf.png"),
  editIcon: require("../assets/images/Edit.png"),
  expand: require("../assets/images/expand-screen.png"),
  viewIcon: require("../assets/images/eyeicon.png"),
  filterIcon: require("../assets/images/filter.png"),
  footerImage: require("../assets/images/Footer.png"),
  qualityGloves: require("../assets/images/gloves.png"),
  glovesIcon: require("../assets/images/gloves-icon.png"),
  glovesTracking: require("../assets/images/gloves-tracking.png"),
  leftHand: require("../assets/images/Hand 1.png"),
  rightHand: require("../assets/images/Hand 2.png"),
  hikarLogo: require("../assets/images/hikar-logo.png"),
  infoIcon: require("../assets/images/infoicon.png"),
  inProgress: require("../assets/images/in-progress.png"),
  iphoneImage: require("../assets/images/iphone.png"),
  kamfetImage: require("../assets/images/kamfet.png"),
  loaderGIF: require("../assets/images/loader.gif"),
  minimizeScreen: require("../assets/images/Minimize-screen.png"),
  notFoundImage: require("../assets/images/notfound.png"),
  notificationIcon: require("../assets/images/notification.png"),
  pairingImage: require("../assets/images/pairing.png"),
  pdfIcon: require("../assets/images/pdf icon.png"),
  pendingImage: require("../assets/images/pending.png"),
  printIcon: require("../assets/images/print.png"),
  profileImage: require("../assets/images/profile.png"),
  rayChemLogo: require("../assets/images/rayChemlogo.png"),
  remarks: require("../assets/images/Remarks.png"),
  report: require("../assets/images/report.png"),
  reportApprovals: require("../assets/images/report-approvals.png"),
  scanLeftHand: require("../assets/images/ScanHand 1.png"),
  scanRightHand: require("../assets/images/ScanHand 2.png"),
  useSetting: require("../assets/images/user-setting.png"),
  Kamfet: require("../assets/images/Kamfet_logo.png"),
  ElectricalGloves: require("../assets/images/ElectricalGloves.jpg"),
  ExaminationModule: require("../assets/images/ExaminationModule.jpg"),
  DemoOR: require("../assets/images/OR.png"),
  RaychemRPGLogo: require("../assets/images/RaychemRPG.png"),
  ExcelIcon: require("../assets/images/icons8-excel-24.png"),
  RawMaterial: require("../assets/images/raw-materials.png"),
  Production: require("../assets/images/production.png"),
  tunnelOven: require("../assets/images/tunnel-oven.png"),
  productionLine: require("../assets/images/production-line.png"),
  sfgStore: require("../assets/images/SFG-store.png"),
  deliveryTruck: require("../assets/images/delivery-truck.png"),
  reportIcon: require("../assets/images/report-icon.png"),
  PDFDownload: require("../assets/images/pdfDownload.png")
};

export const ProductionLineImages={
  dipping: require("../assets/images/ProductionLine/dipping.png"),
  outFeed : require("../assets/images/ProductionLine/out-feed.png"),  
  tumbleOven : require("../assets/images/ProductionLine/tumble-oven.png"),
  tumbleDry: require("../assets/images/ProductionLine/tumble-drying.png"),
  washing: require("../assets/images/ProductionLine/washing.png"),
  cutting: require("../assets/images/ProductionLine/cutting.png"),
  autoClave: require("../assets/images/ProductionLine/auto-clave.png"),
  prongOven: require("../assets/images/ProductionLine/prong-oven.png"),
  acConditing: require("../assets/images/ProductionLine/ac-conditioning.png"),
  chlorination: require("../assets/images/ProductionLine/chlorination.png"),
  electricalTest: require("../assets/images/ProductionLine/electrical-test.png"),
  boxPacking: require("../assets/images/ProductionLine/box-packing.png"),
  polyBag: require("../assets/images/ProductionLine/poly-bag.png"),
  pairing: require("../assets/images/ProductionLine/pairing.png"),
  printing: require("../assets/images/ProductionLine/printing.png"),

  scanTray: require("../assets/images/ProductionLine/scan-tray.png"),
  scanStation: require("../assets/images/ProductionLine/scan-station.png"),
  productionLineIcon: require("../assets/images/ProductionLine/production-line-icon.png"),
}
