import React,{useEffect} from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import SendDocs from "../containers/SendDocs";
import DippingParameters from "../containers/DippingParameters/DippingParameters";
import GlovesTracking from "../containers/GlovesTracking/GlovesTracking";
import Dashboard from "../containers/Dashboard/Dashboard";
import GlovesPairing from "../containers/GlovesPairing/GlovesPairing";
import CubeJsApiWrapper from "../cubejs/CubeJsApiWrapper";
import ReportMain from "../containers/Reports & Approvals/ReportMain";
import Analytics from "../containers/AdvanceAnalytics/Analytics";
import Quality from "../containers/AdvanceAnalytics/Quality";
import CustomerMainReport from "../containers/CustomerReport/CustomerMainReports";
import CertificateofAccorance from "../containers/CustomerReport/Certificate";
import SalesSummary from "../containers/AdvanceAnalytics/SalesMainSummary";
import QualityMain from "../containers/AdvanceAnalytics/QualityMain";
import { VisualPercentageChart } from "../containers/AdvanceAnalytics/VisualPercentageChart";
import { Production } from "../containers/AdvanceAnalytics/Production";
import { SubProduction } from "../containers/AdvanceAnalytics/SubProduction";
import ProductionStatus from "../containers/Dashboard/ProductionStatus";
import AnalyticsStatus from "../containers/Dashboard/AnalyticsStatus";
import CSVReport from "../containers/Dashboard/CSVReport";
import VisualClassWiseChart from "../containers/AdvanceAnalytics/VisualClassWiseChart";
import FinalVisualClassWiseChart from "../containers/AdvanceAnalytics/FinalVisualClassWiseChart";
import {ElectricTestRejectionTable}  from "../containers/AdvanceAnalytics/ElectricTestRejectionTable";
import ElectricClassWiseChart  from "../containers/AdvanceAnalytics/ElectricTestClasswiseyieldTable";
import {ManualDatabase} from "../containers/manualdatabase/ManualDatabase";
import {PackDispatch} from '../containers/Dashboard/PackDispatch';
import { useNavigate } from 'react-router-dom';
import UploadMainReport from "../containers/UploadExcel/UploadFileMain";
import Upload_Excel_History from "../containers/UploadExcel/Upload_Excel_History";
import RandomCSV from "../components/RandomCSC/RandomCSV";
import RawMaterial from "../containers/RawMaterial/RawMaterial";
import RawMaterialReport from "../containers/RawMaterial/RawMaterialReport";
import SFGstore from "../containers/SFGstore/SFGstore";
import SFGHistory from "../containers/SFGstore/SFGHistory";
import ProductionLine from "../containers/ProductionLine/ProductionLine";
import GlovesReport from "../containers/GlovesTracking/GlovesReport";
import ProductionLineStripping from "../containers/ProductionLine/ProductionLineStripping";
import ProductionLineChart from "../containers/ProductionLine/ProductionLineChart";
import SFGPrinter from "../containers/SFGstore/SFGPrinter";



const AuthenticationRoutes = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const currentPath = window.location.pathname;
 
    const endpoints = [
      '/dashboard',
      '/productionStatus',
      '/PackDispatch',
      '/analyticsStatus',
      '/csv-report',
      '/dipping-parameters',
      '/gloves-tracking',
      '/gloves-pairing',
      '/reports',
      '/analytics',
      '/production',
      '/production/production',
      '/quality-analytics',
      '/sales-summary',
      '/analytics/quality',
      '/percentage-chart',
      '/classwise-chart',
      '/manual-database',
      '/rejection-chart',
      '/finalvisual-chart',
      '/customer-report',
      '/certificate',
      '/electric-test-class-wise-yield',
      '/uploadexcel',
      '/upload-excel',
      '/upload-excel-history',
      "/random-uid",
      "/raw-material",
      "/raw-material-report/",
      "/SFG-score",
      "/sfg-history",
      "/production-line",
      "/gloves-report",
      "/production-line-stripping",
      "/production-line-chart",
      "/sfg-printer"
    ];
    


    useEffect(() => {
      // Check if the current path matches a static endpoint
      const isStaticEndpoint = endpoints.some((endpoint) =>
        location.pathname.startsWith(endpoint)
      );
  
      // Separate condition for dynamic route `/raw-material-report/:id`
      const isRawMaterialReport = location.pathname.startsWith("/raw-material-report/");
  
      if (!isStaticEndpoint && !isRawMaterialReport) {
        navigate("/dashboard");
      }
    }, [location.pathname, navigate]);

  

  return (
    <CubeJsApiWrapper>
      <Routes>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/productionStatus" element={<ProductionStatus />} />
        <Route path="/PackDispatch" element={<PackDispatch />} />
        <Route path="/analyticsStatus" element={<AnalyticsStatus />} />
        <Route path="/csv-report" element={<CSVReport />} />
        <Route path="/dipping-parameters" element={<DippingParameters />} />
        <Route path="/gloves-tracking" element={<GlovesTracking />} />
        <Route path="/gloves-pairing" element={<GlovesPairing />} />
        <Route path="/reports" element={<ReportMain />} />
        <Route path="/analytics" element={<Analytics />} />
        <Route path="/production" element={<Production />} />
        <Route path="/production/production" element={<SubProduction />} />
        <Route path="/quality-analytics" element={<QualityMain />} />
        <Route path="/sales-summary" element={<SalesSummary />} />
        <Route path="/analytics/quality" element={<Quality />} />
        <Route path="/percentage-chart" element={<VisualPercentageChart />} />
        <Route path="/classwise-chart" element={<VisualClassWiseChart />} />
        <Route path="/manual-database" element={<ManualDatabase />} />
        <Route path ="/upload-excel" element={<UploadMainReport />}/>
        <Route path ="/upload-excel-history" element={<Upload_Excel_History />}/>
        <Route path="/SFG-score" element={<SFGstore />} />
        <Route path="/sfg-history" element={<SFGHistory/>} />

        <Route
          path="/rejection-chart"
          element={<ElectricTestRejectionTable />}
        />
        <Route
          path="/electric-test-class-wise-yield"
          element={<ElectricClassWiseChart />}
        />
        <Route
          path="/finalvisual-chart"
          element={<FinalVisualClassWiseChart />}
        />
        <Route path="/customer-report" element={<CustomerMainReport />} />
        <Route path="/certificate" element={<CertificateofAccorance />} />

        <Route path="/random-uid" element={<RandomCSV />} />

        <Route path="*" element={<Dashboard />} />

        <Route path="/raw-material" element={<RawMaterial/>} />
        <Route path="/raw-material-report/:id" element={<RawMaterialReport/>}/>
        <Route path="/production-line"  element={<ProductionLine/>} />
        <Route path="/gloves-report" element={<GlovesReport />} />
        <Route path="/production-line-stripping"  element={<ProductionLineStripping/>} />
        <Route path="/production-line-chart"  element={<ProductionLineChart/>} />
        <Route path="/sfg-printer" element={<SFGPrinter />} />
      </Routes>
    </CubeJsApiWrapper>
  );
};
export default AuthenticationRoutes;
