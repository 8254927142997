import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TablePagination,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import "./SFGstore.css";
import COCDatePicker from "../../components/DateTimePicker/DateTimePicker";
import dayjs from "dayjs";
import SearchIcon from "@mui/icons-material/Search";
import { getSFGStoreDetails } from "./services";


const SFGHistory = () => {

  const data = [
    { class: "00", size: 3, length: 380, type: "SC", quantity: 825 },
    { class: "1", size: 9, length: 420, type: "RE", quantity: 760 },
    { class: "2", size: 10, length: 380, type: "SC", quantity: 825 },
    { class: "3", size: 11, length: 420, type: "RE", quantity: 760 },
  ];

  const navigate = useNavigate();
  const [showTime, setShowTime] = useState(false);
  const [startDateReFormat, setStartDateReFormat] = useState(null);
  const [endDateReFormat, setEndDateReFormat] = useState(null);
  const [debouncedSearchText, setDebouncedSearchText] = useState({});
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [SFGStoreDetails, setSFGStoreDetails] = useState([]);
  const [totalCount,setTotalCount] = useState()

  const handleToggleTime = () => {
    setShowTime(!showTime);
  };
  const handleStartDate = (newStartDate) => {
    if (showTime) {
      setStartDateReFormat(newStartDate);
    } else {
      setStartDateReFormat(dayjs(newStartDate).hour(23).minute(59).second(0));
    }
  };

  const handleEndDate = (newEndDate) => {
    if (showTime) {
      setEndDateReFormat(newEndDate);
    } else {
      setEndDateReFormat(dayjs(newEndDate).hour(23).minute(59).second(0));
    }
  };

  const rows = [
    {
      bNo: "I0001",
      dateTime: "02/07/2022 12:30",
      aging: "28 days",
      boxNo: "SFGB01",
      class: "00",
      lotNo: "L042.08",
      size: "9R",
      length: 420,
      type: "SC",
      quantity: 1024,
    },
    {
      bNo: "I0002",
      dateTime: "02/07/2022 10:30",
      aging: "77 days",
      boxNo: "SFGB02",
      class: "1",
      lotNo: "L043.09",
      size: "9R",
      length: 380,
      type: "RE",
      quantity: 560,
    },
  ];
  const initiallValue = {
    Date: "",
    batch__batch_type: "",
    aging: "",
    box_no: "",
    class_id__class_name: "",
    lot_no: "",
    size__size_name: "",
    length__length: "",
    scre_type: "",
    quantity: "",
  };

  const [searchText, setSearchText] = useState(initiallValue);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchText(searchText); // Update the debounced searchText
    }, 500); // 500ms debounce delay

    return () => {
      clearTimeout(handler); // Cleanup the timeout
    };
  }, [searchText]); // Effect runs when searchText changes

  const handleSearchData = async (e) => {
    // Destructure the name and value of the input field that triggered the change
    const { name, value } = e.target;

    // Update the searchText state object with the new value for the specified input field
    setSearchText({ ...searchText, [name]: value });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const dateFormat = "YYYY-MM-DD HH:mm:ss";
  const formatStartDate = startDateReFormat
    ? dayjs(startDateReFormat).format(dateFormat)
    : "";
  const formatEndDate = endDateReFormat
    ? dayjs(endDateReFormat).format(dateFormat)
    : "";
  const fetchData = async () => {

    const searchParams = {
      search: debouncedSearchText,
      page: page,
      rowsPerPage: rowsPerPage,
      start_datetime: formatStartDate,
      end_datetime: formatEndDate,
    };
    try {
      const response = await getSFGStoreDetails(searchParams);
      if (response.data.success == true) {
        setSFGStoreDetails(response.data.payload);
        setTotalCount(response.data.total_quantity)
      }
    } catch (error) {
      setSFGStoreDetails([])
      console.log(error);
    }
  };

  useEffect(() => {
    if (Object.keys(debouncedSearchText).length > 0) {
      fetchData();
    }
  }, [debouncedSearchText, page, rowsPerPage, formatStartDate, formatEndDate]);

  return (
    <div className="dashboard-wrapper page-wraper">
      <div className="page-header">
        <div className="page-back-btn" onClick={()=>navigate(-1)} style={{cursor:"pointer"}}>
          <ArrowBackIcon />
          <span>SFG History</span>
        </div>
        <div className="d-flex">
          <h4 className="d-flex mt-3" style={{fontWeight:400}} >
            Total <b style={{ marginLeft: "10px" }}>{totalCount}</b>
          </h4>
          <FormGroup aria-label="position" row>
            <FormControlLabel
              className="time-picker"
              value="top"
              control={<Switch color="primary" checked={showTime} />}
              label="Time"
              labelPlacement="top"
              onChange={handleToggleTime}
            />
          </FormGroup>

          <COCDatePicker
            time={showTime}
            startDateReFormat={startDateReFormat}
            endDateReFormat={endDateReFormat}
            handleStartDate={handleStartDate}
            handleEndDate={handleEndDate}
            Start_Date_lable={"Start Date"}
            end_Date_lable={"End Date"}
          />
        </div>
      </div>
      <div className="pt-3 table-responsive">
        <TableContainer component={Paper}>
          <Table
            aria-label="Dipping parameter"
            className="dipping-params sfg-score-table"
          >
            <TableHead>
              <TableRow>
                <TableCell>
                  <Checkbox
                    color="primary"
                  //   onClick={(event) => handleSelectAllClick(event)}
                  />
                </TableCell>

                <TableCell>B.No</TableCell>
                <TableCell>Date and Time</TableCell>
                <TableCell>Aging</TableCell>
                <TableCell>Box No</TableCell>
                <TableCell>Class</TableCell>
                <TableCell>LOT No</TableCell>
                <TableCell>Size</TableCell>
                <TableCell>Length</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Quantity</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2} className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="B.No"
                    name="batch__batch_type"
                    value={searchText.batch__batch_type}
                    onChange={handleSearchData}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Date and Time"
                    name="Date"
                    value={searchText.Date}
                    onChange={handleSearchData}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Aging"
                    name="aging"
                    value={searchText.aging}
                    onChange={handleSearchData}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Box No"
                    name="box_no"
                    value={searchText.box_no}
                    onChange={handleSearchData}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Class"
                    name="class_id__class_name"
                    value={searchText.class_id__class_name}
                    onChange={handleSearchData}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Lot No"
                    name="lot_no"
                    value={searchText.lot_no}
                    onChange={handleSearchData}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Size"
                    name="size__size_name"
                    value={searchText.size__size_name}
                    onChange={handleSearchData}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Length"
                    name="length__length"
                    value={searchText.length__length}
                    onChange={handleSearchData}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Type"
                    name="scre_type"
                    value={searchText.scre_type}
                    onChange={handleSearchData}
                  />
                </TableCell>
                <TableCell className="search-table-row">
                  <SearchIcon className="search-icon" />
                  <input
                    type="search"
                    placeholder="Quantity"
                    name="quantity"
                    value={searchText.quantity}
                    onChange={handleSearchData}
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {SFGStoreDetails?.results?.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Checkbox />
                  </TableCell>
                  <TableCell>{row.batch_name}</TableCell>
                  <TableCell>{" "}{dayjs(row.created_at).format("YYYY-MM-DD HH:MM")}</TableCell>
                  <TableCell>{row.aging}</TableCell>
                  <TableCell>{row.box_no}</TableCell>
                  <TableCell>{row.class_name}</TableCell>
                  <TableCell>{row.lot_no}</TableCell>
                  <TableCell>{row.size_name}</TableCell>
                  <TableCell>{row.length_name}</TableCell>
                  <TableCell>{row.scre_type}</TableCell>
                  <TableCell>{row.quantity}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <TablePagination
        className="table-pagination"
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={SFGStoreDetails ? SFGStoreDetails.count : null}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

    </div>
  );
};

export default SFGHistory;
