import React from "react";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { images } from "../../config/images";
import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloseIcon from "@mui/icons-material/Close";
import { Tab } from "react-bootstrap";

const GlovesReport = () => {
  const [openPopup, setOpenPopup] = React.useState(false);
  return (
    <div className="page-wraper">
      <div className="page-header">
        <Link to="/gloves-tracking" className="page-back-btn">
          <ArrowBackIcon />
          <span>Gloves Tracking</span>
        </Link>

        <div className="header-btn-group">
          <img src={images.PDFDownload} alt="pdf" />
        </div>
      </div>
      <div className="table-responsive">
        <Table className="dipping-params">
          <TableHead>
            <TableRow>
              <TableCell style={{ background: "#EFF5FF" }}>Operation</TableCell>
              <TableCell style={{ background: "#EFF5FF" }}>Date</TableCell>
              <TableCell style={{ background: "#EFF5FF" }}>Shift</TableCell>
              <TableCell style={{ background: "#EFF5FF" }}>
                Start Date Time
              </TableCell>
              <TableCell style={{ background: "#EFF5FF" }}>
                End Date Time
              </TableCell>
              <TableCell style={{ background: "#EFF5FF" }}>Good</TableCell>
              <TableCell style={{ background: "#EFF5FF" }}>Reject</TableCell>
              <TableCell style={{ background: "#EFF5FF" }}>Hold</TableCell>
              <TableCell style={{ background: "#EFF5FF" }}>
                Operator Name
              </TableCell>
              <TableCell style={{ background: "#EFF5FF" }}>Remarks</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className="gloves-report-body">
            <TableRow>
              <TableCell>
                <div className="icon-text-container">
                  <IconButton size="small" onClick={() => setOpenPopup(true)}>
                    <VisibilityIcon />
                  </IconButton>
                  <span>
                    <b>Dipping</b>
                  </span>
                </div>
              </TableCell>
              <TableCell>12/12/2021</TableCell>
              <TableCell>1</TableCell>
              <TableCell>12/12/2021 12:00</TableCell>
              <TableCell>12/12/2021 12:30</TableCell>
              <TableCell>
                <Box
                  sx={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px",
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "#00AB66",
                    backgroundColor: "#E8F8F2",
                    border: "1px solid #00AB66",
                    borderRadius: "5px",
                  }}
                >
                  40
                </Box>
              </TableCell>
              <TableCell>
                <Box
                  sx={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px",
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "#E31E24",
                    backgroundColor: "#FFECED",
                    border: "1px solid #E31E24",
                    borderRadius: "5px",
                  }}
                >
                  13
                </Box>
              </TableCell>
              <TableCell>
                <Box
                  sx={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px",
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "#1C407B",
                    backgroundColor: "#E8F1FF",
                    border: "1px solid #1C407B",
                    borderRadius: "5px",
                  }}
                >
                  02
                </Box>
              </TableCell>
              <TableCell>Shubham</TableCell>
              <TableCell>Lorem Ipsum is simply dum..</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <div className="icon-text-container">
                  <IconButton size="small">
                    <VisibilityIcon />
                  </IconButton>
                  <span>
                    <b>Dipping</b>
                  </span>
                </div>
              </TableCell>
              <TableCell>12/12/2021</TableCell>
              <TableCell>1</TableCell>
              <TableCell>12/12/2021 12:00</TableCell>
              <TableCell>12/12/2021 12:30</TableCell>
              <TableCell>
                <Box
                  sx={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px",
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "#00AB66",
                    backgroundColor: "#E8F8F2",
                    border: "1px solid #00AB66",
                    borderRadius: "5px",
                  }}
                >
                  40
                </Box>
              </TableCell>
              <TableCell>
                <Box
                  sx={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px",
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "#E31E24",
                    backgroundColor: "#FFECED",
                    border: "1px solid #E31E24",
                    borderRadius: "5px",
                  }}
                >
                  13
                </Box>
              </TableCell>
              <TableCell>
                <Box
                  sx={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px",
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "#1C407B",
                    backgroundColor: "#E8F1FF",
                    border: "1px solid #1C407B",
                    borderRadius: "5px",
                  }}
                >
                  02
                </Box>
              </TableCell>
              <TableCell>Shubham</TableCell>
              <TableCell>Lorem Ipsum is simply dum..</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <div className="icon-text-container">
                  <IconButton size="small">
                    <VisibilityIcon />
                  </IconButton>
                  <span>
                    <b>Dipping</b>
                  </span>
                </div>
              </TableCell>
              <TableCell>12/12/2021</TableCell>
              <TableCell>1</TableCell>
              <TableCell>12/12/2021 12:00</TableCell>
              <TableCell>12/12/2021 12:30</TableCell>
              <TableCell>
                <Box
                  sx={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px",
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "#00AB66",
                    backgroundColor: "#E8F8F2",
                    border: "1px solid #00AB66",
                    borderRadius: "5px",
                  }}
                >
                  40
                </Box>
              </TableCell>
              <TableCell>
                <Box
                  sx={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px",
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "#E31E24",
                    backgroundColor: "#FFECED",
                    border: "1px solid #E31E24",
                    borderRadius: "5px",
                  }}
                >
                  13
                </Box>
              </TableCell>
              <TableCell>
                <Box
                  sx={{
                    display: "inline-flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "10px",
                    fontSize: "14px",
                    fontWeight: "bold",
                    color: "#1C407B",
                    backgroundColor: "#E8F1FF",
                    border: "1px solid #1C407B",
                    borderRadius: "5px",
                  }}
                >
                  02
                </Box>
              </TableCell>
              <TableCell>Shubham</TableCell>
              <TableCell>Lorem Ipsum is simply dum..</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
      {openPopup && (
        <>
          <div
            className="modal-container"
            style={{ height: "40vh", top: "35vh" }}
          >
            <div className="modal-header">
              <div className="modal-title">WASHING MACHINE PROCESS SHEET</div>
              <div
                onClick={() => setOpenPopup(false)}
                style={{ cursor: "pointer" }}
              >
                <CloseIcon />
              </div>
            </div>
            <div className="modal-table-wrapper">
              <Table className="dipping-params">
                <TableHead>
                  <TableRow>
                    <TableCell>Max Temp (°C)</TableCell>
                    <TableCell>Min Temp (°C)</TableCell>
                    <TableCell>Time (Minutes)</TableCell>
                    <TableCell>Water Level (Liters)</TableCell>
                    <TableCell>Cycle Start Time</TableCell>
                    <TableCell>Cycle End Time</TableCell>
                    <TableCell>Operator</TableCell>
                    <TableCell>SuperVisor</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody className="gloves-report-body">
                  <TableRow>
                    <TableCell>50°C</TableCell>
                    <TableCell>36°C</TableCell>
                    <TableCell>60</TableCell>
                    <TableCell>590</TableCell>
                    <TableCell>12:00</TableCell>
                    <TableCell>13:00</TableCell>
                    <TableCell>Vijay</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </div>
          </div>
          <div
            className="modal-overlay"
            onClick={() => setOpenPopup(false)}
          ></div>
        </>
      )}
    </div>
  );
};
export default GlovesReport;
