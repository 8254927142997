import { request } from "../../api/api";


export const getSFGStoreData = (searchParams) => {
  const queryParams = Object.entries({
    class_id__class_name: searchParams.search.class_id__class_name,
    size__size_name: searchParams.search.size__size_name,
    length__length: searchParams.search.length__length,
    scre_type: searchParams.search.scre_type,
    quantity: searchParams.search.quantity,
    page: searchParams.page + 1,
    page_size: searchParams.rowsPerPage,
  })
    .filter(([, value]) => value !== undefined && value !== "") // Filter out undefined or empty values
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`) // Encode key-value pairs
    .join("&");

  return request.get(`/sfg_store/getSFGStoreData/?${queryParams}`);
};
export const getSFGStoreDetails = (searchParams) => {
  const queryParams = Object.entries({
    search: searchParams.search.Date,
    batch__batch_type: searchParams.search.batch__batch_type,
    aging: searchParams.search.aging,
    box_no__box_no: searchParams.search.box_no,
    class_id__class_name: searchParams.search.class_id__class_name,
    lot_no: searchParams.search.lot_no,
    size__size_name: searchParams.search.size__size_name,
    length__length: searchParams.search.length__length,
    scre_type: searchParams.search.scre_type,
    quantity: searchParams.search.quantity,
    page: searchParams.page + 1,
    page_size: searchParams.rowsPerPage,
    start_datetime : searchParams.start_datetime,
    end_datetime : searchParams.end_datetime,
  })
    .filter(([, value]) => value !== undefined && value !== "") // Filter out undefined or empty values
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`) // Encode key-value pairs
    .join("&");

  return request.get(`/sfg_store/getSFGBoxHistoryDetails/?${queryParams}`);
};

export const getSFGBoxNo = ()=>{
  return request.get("/sfg_store/getSFGBoxNo/")
}

export const getSFGBagDetails = (params) =>{
  return request.post("/sfg_store/getSFGBagDetail/",params)
}

export const createSFGBoxAndHistory = (params) =>{
  return request.post("/sfg_store/createSFGBoxAndHistory/",params)
}

export const printAgainSFGBoxLabel = (params) =>{
  return request.post("/sfg_store/printAgainSFGBoxLabel/",params)
}