import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "./ProductionLine.css";
import { ProductionLineImages } from "../../config/images";


const ProductionLine = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [scanStation, setScanStation] = useState(false);
    const [batchAssign, setBatchAssign] = useState(false);

    const handleTrayScan = () => {
        setLoading(true); // Show loader

        setTimeout(() => {
            setLoading(false); // Hide loader after completion
            setScanStation(true);
        }, 3000);
    };

    const handleStationScan = () => {
        // navigate("/production-line-stripping");
        setBatchAssign(true);
    };
    const handleProductionLineClick = () => {
        navigate("/production-line-chart");
    };

    return (
        <div className="min-h-screen bg-[#f5f3ff] p-8 dashboard-wrapper page-wraper">
            <div className="page-header">
                <Link to="/dashboard" className="page-back-btn">
                    <ArrowBackIcon />
                    <span>Production Line</span>
                </Link>
                {batchAssign && (
                    <div className="header-btn-group">
                        <img src={ProductionLineImages.productionLineIcon} alt="" onClick={handleProductionLineClick} style={{ cursor: "pointer" }} />

                    </div>
                )}

            </div>

            <div className="pt-4 procution-line-title" style={{ fontSize: "36px", color: "#222D39", }}>
                {scanStation ? "Scan a Station" : "Scan a Tray"}
            </div>
            <div className="production-line-scanner">
                <img style={{ padding: "20px" }}
                    src={scanStation ? ProductionLineImages.scanStation : ProductionLineImages.scanTray}
                    alt=""
                    onClick={() => scanStation ? handleStationScan() : handleTrayScan()} />
            </div>
            {loading && (
                <div className="pt-5">
                    <span className="loader"></span>
                </div>
            )}
        </div>
    )
}
export default ProductionLine;
