import React, { useState } from "react";
import { Link } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Dialog, DialogTitle, DialogContent, TextField, Button } from "@mui/material";
import "./ProductionLine.css";
import { ProductionLineImages } from "../../config/images";

const ProductionLineChart = () => {
    const [open, setOpen] = useState(false);
    const [selectedStation, setSelectedStation] = useState(null);
    const [formData, setFormData] = useState({});

    const [status, setStatus] = useState({
        "Dipping": "completed",
        "Out Feed Visual inspections": "in-progress",
        "Leaching": "pending",
        "Tumble Oven": "pending",
        "Stripping": "pending",
        "Visual Inspection": "pending",
        "Static Leaching": "pending",
        "Tumble Drying": "pending",
        "Washing": "pending",
        "Cutting": "pending",
        "Autoclave": "pending",
        "Prong Oven": "pending",
        "AC Conditioning": "pending",
        "Hanco washing": "pending",
        "Chlorination": "pending",
        "Extra Ac Conditioner": "pending",
        "Electrical Test": "pending",
        "Box Packing": "pending",
        "Poly Bag Policing": "pending",
        "Pairing": "pending",
        "Printing": "pending",
        "Final Visual Inspection": "pending"
    });

    // Define process steps with positions & icons
    const processSteps = [
        { x: 50, y: 110, image: ProductionLineImages.dipping, label: "Dipping", fields: [{ name: "max_temp", label: "Max Temp (°C)" }, { name: "min_temp", label: "Min Temp (°C)" }] },
        { x: 220, y: 110, image: ProductionLineImages.outFeed, label: "Out Feed Visual inspections" },
        { x: 420, y: 110, image: "", label: "Leaching" },
        { x: 570, y: 110, image: ProductionLineImages.tumbleOven, label: "Tumble Oven" },
        { x: 770, y: 110, image: "", label: "Stripping" },
        { x: 920, y: 110, image: ProductionLineImages.outFeed, label: "Visual Inspection" },
        { x: 1070, y: 110, image: "", label: "Static Leaching" },
        { x: 170, y: 260, image: ProductionLineImages.tumbleDry, label: "Tumble Drying" },
        { x: 320, y: 260, image: ProductionLineImages.washing, label: "Washing" },
        { x: 470, y: 260, image: ProductionLineImages.cutting, label: "Cutting" },
        { x: 620, y: 260, image: ProductionLineImages.autoClave, label: "Autoclave" },
        { x: 770, y: 260, image: ProductionLineImages.prongOven, label: "Prong Oven" },
        { x: 920, y: 260, image: ProductionLineImages.tumbleDry, label: "Tumble Drying" },
        { x: 1050, y: 260, image: ProductionLineImages.washing, label: "Washing" },
        { x: 170, y: 410, image: ProductionLineImages.acConditing, label: "AC Conditioning" },
        { x: 370, y: 410, image: ProductionLineImages.washing, label: "Hanco washing" },
        { x: 520, y: 410, image: ProductionLineImages.chlorination, label: "Chlorination" },
        { x: 670, y: 410, image: ProductionLineImages.acConditing, label: "Extra Ac Conditioner" },
        { x: 870, y: 410, image: ProductionLineImages.prongOven, label: "Prong Oven" },
        { x: 1070, y: 410, image: ProductionLineImages.electricalTest, label: "Electrical Test" },
        { x: 150, y: 560, image: ProductionLineImages.boxPacking, label: "Box Packing" },
        { x: 370, y: 560, image: ProductionLineImages.polyBag, label: "Poly Bag Policing" },
        { x: 520, y: 560, image: ProductionLineImages.pairing, label: "Pairing" },
        { x: 670, y: 560, image: ProductionLineImages.printing, label: "Printing" },
        { x: 870, y: 560, image: ProductionLineImages.outFeed, label: "Final Visual Inspection" },
        { x: 1070, y: 560, image: ProductionLineImages.tumbleOven, label: "Prong Oven" }
    ];

    const handleClick = (station) => {
        setSelectedStation(station);
        setFormData({});
        setOpen(true);
    };

    // Handle Form Change
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    // Close Dialog
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <div className="min-h-screen bg-[#f5f3ff] p-8 dashboard-wrapper page-wraper">
            <div className="page-header">
                <Link to="/dashboard" className="page-back-btn">
                    <ArrowBackIcon />
                    <span>Production Line</span>
                </Link>
            </div>
            <div className="max-w-6xl mx-auto">
                <svg
                    viewBox="-50 50 1250 600"
                    className="w-full"
                // style={{ maxHeight: "80vh" }}
                >
                    <path
                        d="M50 100 L1100 100 
                            Q1150 100 1150 150
                            L1150 200 
                            Q1150 250 1100 250
                            L100 250
                            Q50 250 50 300
                            L50 350 
                            Q50 400 100 400
                            L1100 400
                            Q1150 400 1150 450
                            L1150 500 
                            Q1150 550 1100 550
                            L150 550"
                        fill="none"
                        stroke="#1e3a8a"
                        stroke-width="4"
                    />
                    {/* Dynamically render process stations */}
                    {processSteps.map((station) => (
                        <ProcessStation
                            key={station.label}
                            x={station.x}
                            y={station.y}
                            image={station.image}
                            label={station.label}
                            status={status[station.label]}
                            onClick={() => handleClick(station)}
                        />
                    ))}
                </svg>
            </div>


            {/* DIALOG BOX */}
            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                <DialogTitle className="productionline-dialog-title">
                    {selectedStation?.label}
                </DialogTitle>
                <DialogContent>
                    {selectedStation?.fields?.map((field) => (
                        <TextField
                            key={field.name}
                            name={field.name}
                            label={field.label}
                            value={formData[field.name] || ""}
                            onChange={handleChange}
                            fullWidth
                            margin="dense"
                            variant="outlined"
                        />
                    ))}
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleClose}
                        fullWidth
                        style={{ marginTop: "15px", backgroundColor: "#0d3b66" }}
                    >
                        Update
                    </Button>
                </DialogContent>
            </Dialog>

        </div>
    );
}
export default ProductionLineChart;

function ProcessStation({ x, y, image, label, status, onClick }) {
    const getStatusColor = () => {
        switch (status) {
            case "completed":
                return "green";
            case "in-progress":
                return "red";
            default:
                return "white";
        }
    };

    const isHighlighted = status === "completed" || status === "in-progress";

    return (
        <g
            transform={`translate(${x - 30} ${y + 20})`}
            onClick={onClick}
            style={{ cursor: "pointer" }}
        >
            <circle
                cx={30}
                cy={-30}
                r={10}
                fill={getStatusColor()}
                stroke={isHighlighted ? "none" : "#1e3a8a"}
                strokeWidth="2"
                style={{
                    filter: isHighlighted ? `drop-shadow(0px 0px 8px ${getStatusColor()})` : "none",
                }}
            />
            
            {/* Render the image instead of text */}
            <image
                href={image}
                x="20"
                y="0"
                width="30"
                height="30"
            />
            <text x="0" y="50" style={{fontSize:"14px"}} textAnchor="middle" transform="translate(30 0)">
                {label}
            </text>
        </g>
    );
}
